import React, { useEffect, useState } from 'react'
import { shipDict } from 'util/shipClasses'
import { Form } from 'react-bootstrap'
import { FloatingLabel } from 'react-bootstrap'
import { Button, Card } from 'react-bootstrap'
import { NavigateFunction, useNavigate, useLocation } from 'react-router-dom'
import { Auth0Audience } from 'api/helpers'
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { Console } from 'console'
import getImageUseState from 'api/Images'
import { searchImages } from 'api/Images'
// import { Modal } from "react-bootstrap";
// import { Tank } from "../../helpers/tankClass";
// import "./tankSelect.css";
/* eslint @typescript-eslint/no-var-requires: "off" */

const TankSelect = (): JSX.Element => {
  const { state }: any = useLocation()
  const [tankSelected, setTankSelected] = useState<string>('')
  // eslint-disable-next-line
  const [floorImage, setFloorImage] = useState<Blob | null>(null)
  const [modelImage, setModelImage] = useState<Blob | null>(null)
  const [siteId, setSiteId] = useState<string>('')
  const [imagesInfo, setImagesInfo] = useState<Array<any>>([])
  const [units, setUnits] = useState<Array<any>>([])
  const [unitsBool, setUnitsBool] = useState<boolean>(false)
  const navigate: NavigateFunction = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [tokenString, setToken] = useState<string>('')

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const options = { audience: Auth0Audience() }
    const token = await getAccessTokenSilently(options)
    setToken(token)
  }

  useEffect(() => {
    if (state === null) {
      navigate('/')
    } else {
      getToken(getAccessTokenSilently)
    }
  }, [])
  useEffect(() => {
    if (tokenString !== '') {
      fetch('https://rolodex.dev.cloud.geckorobotics.com/api/v2/entries/load', {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          type: 'site',
          filters: [{ type: 'site', key: 'display_name', op: '=', value: 'USS Midway' }],
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          data[0].entry.id ? setSiteId(data[0].entry.id) : setSiteId('')
        })
    } else {
      console.log('waiting on token')
    }
  }, [tokenString])

  useEffect(() => {
    if (siteId !== '') {
      fetch('https://rolodex.dev.cloud.geckorobotics.com/api/v2/entries/load', {
        method: 'post',
        headers: {
          Authorization: `Bearer ${tokenString}`,
          'Content-Type': 'application/json',
        },

        //make sure to serialize your JSON body
        body: JSON.stringify({
          type: 'unit',
          tags: {
            site: siteId,
          },
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          setUnits(unitsParser(data))
          setUnitsBool(true)
          searchImages(tokenString, 'site', siteId, setImagesInfo)
        })
    } else {
      console.log('siteID loading...')
    }
  }, [siteId])

  useEffect(() => {
    if (imagesInfo.length > 0) {
      imagesInfo.map((imageInfo) => {
        if (imageInfo.id.includes('floor')) {
          getImageUseState(imageInfo.id, tokenString, setFloorImage)
        }
        if (imageInfo.id.includes('model')) {
          getImageUseState(imageInfo.id, tokenString, setModelImage)
        }
      })
    }
  }, [imagesInfo])

  const onTankSearch = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const tank = e.target.value

    setTankSelected(tank)
  }

  const handleClick = () => {
    units.map((unit, index) => {
      if (unit.entry.data.display_name === tankSelected) {
        navigate('/tank-inspection', {
          state: {
            shipType: state.shipType,
            shipClass: state.shipClass,
            shipName: state.shipName,
            tankName: tankSelected,
            unitId: unit.entry.id,
            ascansBol: unit.entry.data.ascans,
            photosBol: unit.entry.data.photos,
            videosBol: unit.entry.data.videos,
            video_link: unit.entry.data.video_link,
          },
        })
      }
    })
  }
  if (unitsBool && modelImage !== null && floorImage !== null) {
    return (
      <div className='TankSelect'>
        <div className='TankSelect__page-master-div'>
          <Card className='TankSelect__card'>
            <Card.Body className='TankSelect__card-body'>
              <div className='TankSelect__image-div'>
                <h1>USS {state.shipName}</h1>
                <img
                  className='TankSelect__midway-model'
                  src={URL.createObjectURL(modelImage)}
                  // src='gs://gecko-files-api-bucket-dev/tank_inspector_photos/uss_midway_model'
                  alt='USS Midway Side Model'
                  // height={225}
                  // width={860}
                ></img>
                <h1>View Ship Level</h1>
                <img
                  className='TankSelect__main-deck-img'
                  // src='/starts_tanks.png'
                  src={URL.createObjectURL(floorImage)}
                  alt='Main Deck USS Midway'></img>

                <h2 className='TankSelect__center-content-text'>Tank Selected:</h2>

                <div className='TankSelect__center-content-div'>
                  <FloatingLabel
                    className='TankSelect__tank-search'
                    controlId='floatingSelect'
                    label='Search Tanks'>
                    <Form.Select
                      aria-label='Floating label select example'
                      onChange={(e) => {
                        onTankSearch(e)
                      }}>
                      {/* {midwayTanks.map((e) => { */}
                      {units.map((e: any) => {
                        return (
                          <option
                            value={e.entry.data.display_name}
                            key={e.entry.data.display_name}>
                            {e.entry.data.display_name}
                          </option>
                        )
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </div>

                <Button style={{ border: '0px solid white' }} onClick={handleClick}>
                  View {`${tankSelected}`} Inspection
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    )
  } else {
    return <p>Loading...</p>
  }
}

function unitsParser(units: Array<any>): Array<any> {
  let newUnits: Array<any> = []
  units.map((unit) => {
    if (unit.entry.data.photos === undefined) {
    } else {
      newUnits.push(unit)
    }
  })
  return newUnits
}

export default TankSelect
