import React, { useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import './header.css'

export const Header = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = useState<string | null>()
  const [pathText, setPathText] = useState<string | null>()
  const [smallPathText, setSmallPathText] = useState<string | null>()

  useEffect(() => {
    const path: string = location.pathname
    const state: any = location.state
    if (state !== null) {
      switch (path) {
        case '/':
          setPage('Ship Select')
          setPathText('')
          setSmallPathText('')
          break
        case '/tank-select':
          setPage('Tank Select')
          setPathText(
            `${state.shipType.split('_').join(' ')} / ${state.shipClass
              .split('_')
              .join(' ')} Class / USS ${state.shipName.split('_').join(' ')}`
          )
          setSmallPathText(`...USS ${state.shipName.split('_').join(' ')}`)
          break
        case '/tank-inspection':
          setPage('Tank Inspection')
          setPathText(
            `${state.shipType.split('_').join(' ')} / ${state.shipClass
              .split('_')
              .join(' ')} Class / USS ${state.shipName.split('_').join(' ')} / ${
              state.tankName
            }`
          )
          setSmallPathText(`...${state.tankName}`)
          break
      }
    }
  }, [location])

  return (
    <div className='Header__header-div'>
      <Row className='Header__row'>
        <Col xs={4} className='Header__header-col'>
          <Link to='/'>
            <img
              className='Header__header-logo'
              src='gecko_logo.svg'
              alt='gecko logo'></img>
            <img
              className='Header__header-logo-small'
              src='gecko_logo_small.svg'
              alt='gecko logo small'></img>
          </Link>
        </Col>
        <Col xs={4} className='Header__header-center-col'>
          <h1 className='Header__header-text'>{page}</h1>
          <h5 className='Header__header-pathtext'>{pathText}</h5>
          <h5 className='Header__header-pathtext-small'>{smallPathText}</h5>
        </Col>
      </Row>
    </div>
  )
}
