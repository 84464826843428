import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export interface annotationModalProps {
  show: boolean;
  currentTimestamp: number;
  onModalHide: () => void;
  handleTankAdded: (modalAnnotation: string) => void;
}

export function AnnotationModal(props: annotationModalProps) {
  const [annotationText, setAnnotationText] = useState<string>("");

  useEffect(() => {
    setAnnotationText("");
  }, [props.show]);

  return (
    <Modal show={props.show} onHide={() => props.onModalHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          Add a New Annotation at {props.currentTimestamp} seconds
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Annotation: </Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => {
              setAnnotationText(e.target.value);
            }}
            value={annotationText}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          onClick={() => props.handleTankAdded(annotationText)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
