const { REACT_APP_ENVIRONMENT } = process.env

/*
DON'T PUT SENSITIVE VALUES IN THIS FILE
 */

const PROD_ENV = 'prod'

const AUTH0_CLIENT_ID_DEV = 'LzOxUtp7LPh194yrKhaNzzZ2msCaNNcJ'
const AUTH0_CLIENT_ID_PROD = 'AyrD3sVv2kdS3c4vRVQshGDdMhoBQqWQ'

export const Auth0ClientId = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_CLIENT_ID_PROD : AUTH0_CLIENT_ID_DEV
}

const AUTH0_URL_DEV = 'dev-7ng94g85.us.auth0.com'
const AUTH0_URL_PROD = 'geckorobotics.us.auth0.com'

export const Auth0Url = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_URL_PROD : AUTH0_URL_DEV
}

const OBJECT_SERVICE_URL_DEV = 'https://object-service.dev.cloud.geckorobotics.com'
const OBJECT_SERVICE_URL_PROD = 'https://object-service.cloud.geckorobotics.com'

export const ObjectServiceUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? OBJECT_SERVICE_URL_PROD
    : OBJECT_SERVICE_URL_DEV
}

const ROLODEX_URL_DEV = "https://rolodex.dev.cloud.geckorobotics.com/"
const ROLODEX_URL_PROD = "https://rolodex.cloud.geckorobotics.com/"

export const RolodexUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? ROLODEX_URL_PROD
    : ROLODEX_URL_DEV
}


const AUTH0_AUDIENCE_DEV = 'dev.cloud.geckorobotics.com'
const AUTH0_AUDIENCE_PROD = 'cloud.geckorobotics.com'

export const Auth0Audience = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_AUDIENCE_PROD : AUTH0_AUDIENCE_DEV
}
