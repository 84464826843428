import React, { useState, useEffect } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import './formatFormStrings.css'

export interface FormatFormStringsProps {
  formStrings: string[]
}

export function FormatFormStrings(props: FormatFormStringsProps) {
  //   const titleStrings: string[] = [
  //     'Tank Name:',
  //     'Date Inspected:',
  //     'Inspector:',
  //     'Inspection Types Completed:',
  //     'Entry Required:',
  //     'Damage Level:',
  //     'Action Required:',
  //     'Next Inspection Date:',
  //     'Notes:',
  //   ]

  // function to check if string is a title string
  //   const isTitleString = (string: string) => {
  //     //check any part of string is in titleStrings
  //     return titleStrings.some((titleString) => string.includes(titleString))
  //   }
  return (
    <div>
      {props.formStrings.map((formString, index) => {
        const subString = formString.split(':')
        return (
          <div key={index} className='TankInspector__FormatFormStrings-form-string-div'>
            <p className='TankInspector__FormatFormStrings-form-string-p'>
              <b style={{ color: 'grey' }}>{subString[0]}:</b>
              {subString[1]}
            </p>
          </div>
        )
      })}
    </div>
  )
}
