import './App.css'
import { useState } from 'react'
import Content from './pages/Content'
import { SWRConfig } from 'swr'
import { useNavigate } from 'react-router-dom'
import { GeckoThemeProvider, GeckoTheme, ThemeType, ErrorBanner } from 'gecko-ui'
import { Auth0Audience, Auth0ClientId, Auth0Url } from './api/helpers'
import { AppState, Auth0Provider } from '@auth0/auth0-react'

const App = () => {
  let navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) ?? window.location.pathname)
  }
  const [showBanner, setShowBanner] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  return (
    <Auth0Provider
      domain={Auth0Url()}
      clientId={Auth0ClientId()}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={Auth0Audience()}>
      <GeckoThemeProvider theme={GeckoTheme as ThemeType}>
        <SWRConfig
          value={{
            onSuccess: (data, key, config) => {
              setShowBanner(false)
              setErrorMessage(undefined)
            },
            onError: (error, key) => {
              setShowBanner(true)
              setErrorMessage(error.message)
            },
            shouldRetryOnError: false,
            errorRetryCount: 1,
          }}>
          <ErrorBanner
            fullWidth={false}
            showBanner={showBanner}
            errorMessage={errorMessage}
          />
          <Content />
        </SWRConfig>
      </GeckoThemeProvider>
    </Auth0Provider>
  )
}

export default App
