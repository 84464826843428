interface midwayTankInterface {
  key: string
  value: string
}

interface shipDictInterface {
  [key: string]: any
  shipTypes: string[]
  Aircraft_Carriers: string[]
  Amphibious_Warfare_Ships: string[]
  Cruisers: string[]
  Destroyers: string[]
  Frigates: string[]
  Littoral_Combat_Ships: string[]
  Mine_Countermeasures_Ships: string[]
  Patrol_Ships: string[]
  Submarines: string[]

  Nimitz: string[]
  Gerald_R_Ford: string[]
  Midway: string[]
  Wasp: string[]
  America: string[]
  Blue_Ridge: string[]
  San_Antonio: string[]
  Whidbey: string[]
  Harpers_Ferry: string[]
  Lewis_B_Puller: string[]
  Ticonderoga: string[]
  Arleigh_Burke: string[]
  Zumwalt: string[]
  Constellation: string[]
  Freedom: string[]
  Independence: string[]
  Avenger: string[]
  Cyclone: string[]
  Los_Angeles: string[]
  Seawolf: string[]
  Virginia: string[]
  Ohio: string[]

  Midway_Tanks: Array<midwayTankInterface>
}





export const shipDict: shipDictInterface = {
  shipTypes: [
    "Aircraft Carriers",
    "Amphibious Warfare Ships",
    "Cruisers",
    "Destroyers",
    "Frigates",
    "Littoral Combat Ships",
    "Mine Countermeasures Ships",
    "Patrol Ships",
    "Submarines",
  ],

  Aircraft_Carriers: ["Nimitz", "Gerald R Ford", "Midway"],
  Amphibious_Warfare_Ships: [
    "Wasp",
    "America",
    "Blue Ridge",
    "San Antonio",
    "Whidbey",
    "Harpers Ferry",
    "Lewis B Puller",
  ],
  Cruisers: ["Ticonderoga"],
  Destroyers: ["Arleigh Burke", "Zumwalt"],
  Frigates: ["Constellation"],
  Littoral_Combat_Ships: ["Freedom", "Independence"],
  Mine_Countermeasures_Ships: ["Avenger"],
  Patrol_Ships: ["Cyclone"],
  Submarines: ["Los Angeles", "Seawolf", "Virginia", "Ohio"],

  Nimitz: [],
  Gerald_R_Ford: [],
  Midway: ["Midway"],
  Wasp: [],
  America: [],
  Blue_Ridge: [],
  San_Antonio: [],
  Whidbey: [],
  Harpers_Ferry: [],
  Lewis_B_Puller: [],
  Ticonderoga: [],
  Arleigh_Burke: [],
  Zumwalt: [],
  Constellation: [],
  Freedom: [],
  Independence: [],
  Avenger: [],
  Cyclone: [],
  Los_Angeles: [],
  Seawolf: [],
  Virginia: [],
  Ohio: [],

  Midway_Tanks: [
    {
      key: "Midway-Tank-1",
      value: "Tank-1",
    },
    {
      key: "Midway-Tank-2",
      value: "Tank-2",
    },
    {
      key: "Midway-Tank-3",
      value: "Tank-3",
    },
    {
      key: "Midway-Tank-4",
      value: "Tank-4",
    },
  ],
};
