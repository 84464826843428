import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { FloatingLabel } from 'react-bootstrap'
import { shipDict } from 'util/shipClasses'
import { Alert } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { NavigateFunction, useNavigate } from 'react-router-dom'

// function LoggedInContent() {
//   const { getAccessTokenSilently } = useAuth0()

//   const [tokenBol, setTokenBol] = React.useState<boolean>(false)
//   const [token_string, setToken] = React.useState<string>('hello')

//   const getToken = async (
//     getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
//   ) => {
//     const options = { audience: Auth0Audience() }
//     const token = await getAccessTokenSilently(options)
//     setToken(token)
//     setTokenBol(true)
//   }

//   React.useEffect(() => {
//     getToken(getAccessTokenSilently)
//   }, [])

//   if (tokenBol) {
//     return <Choose3DFile token={token_string}></Choose3DFile>
//   } else {
//     return <p>Loading...</p>
//   }
// }

export const ShipSelect = (): JSX.Element => {
  const [shipType, setShipType] = useState<string>('Aircraft_Carriers')
  const [shipClass, setShipClass] = useState<string>('Nimitz')
  // eslint-disable-next-line
  const [shipName, setShipName] = useState<string>('')
  const navigate: NavigateFunction = useNavigate()

  const handleClick = () => {
    navigate('/tank-select', {
      state: {
        shipType: shipType,
        shipClass: shipClass,
        // shipName: shipName,
        shipName: 'Midway',
      },
    })
  }

  return (
    <div className='ShipSelect__ship-type-card-master-div'>
      {/* <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to="tank-select">Tank Select</Link> |{" "}
        <Link to="tank-inspection">Tank Inspection</Link>
      </nav> */}
      <div className='ShipSelect__ship-type-card-content-div'>
        <Card className='ShipSelect__ship-type-card'>
          <Card.Body className='ShipSelect__ship-type-card-body'>
            <div className='ShipSelect__ship-select-dropdown-div'>
              <h2 className='ShipSelect__ship-select-text'>Choose Ship Type</h2>
              <FloatingLabel
                className='ShipSelect__floating-label-ship-select'
                controlId='floatingSelect'
                label='Choose Ship Type'>
                <Form.Select
                  aria-label='Floating label select example'
                  onChange={(e) => {
                    setShipType(e.target.value.split(' ').join('_'))
                  }}>
                  {shipDict['shipTypes'].map((e: any) => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Select>
              </FloatingLabel>
              <h2 className='ShipSelect__ship-select-text'>Choose Ship Class</h2>
              <div className='App_ship-class-div'>
                <FloatingLabel
                  className='ShipSelect__floating-label-ship-select'
                  controlId='floatingSelect'
                  label='Choose Ship Class'>
                  <Form.Select
                    aria-label='Floating label select example'
                    onChange={(e) => {
                      setShipClass(e.target.value.split(' ').join('_'))
                    }}>
                    {shipDict[shipType].map((e: any) => {
                      return (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      )
                    })}
                  </Form.Select>
                </FloatingLabel>
              </div>
              <h2 className='ShipSelect__ship-select-text'>Choose Ship Name</h2>
              <FloatingLabel
                className='ShipSelect__floating-label-ship-select'
                controlId='floatingSelect'
                label='Choose Ship Name'>
                <Form.Select
                  aria-label='Floating label select example'
                  onChange={(e) => {
                    setShipName(e.target.value.split(' ').join('_'))
                  }}>
                  {shipDict[shipClass].map((e: any) => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                </Form.Select>
              </FloatingLabel>
            </div>
            <div className='ShipSelect__ship-select-button-div'>
              {shipClass !== 'Midway' ? (
                <Alert
                  key='primary'
                  variant='primary'
                  className='ShipSelect__ship-select-alert'>
                  Ship Name Required to Continue
                </Alert>
              ) : (
                <Button className='ShipSelect__view-tanks-button' onClick={handleClick}>
                  View Tanks
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
