import { useState, useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

interface inspectionModalProps {
  show: boolean
  tankName: string
  dateInspected: string
  inspector: string
  inspectionTypes: string
  entryRequired: string
  damageLevel: string
  actionRequired: string
  nextInspectionDate: string
  onInspectionModalHide: (
    damage: string,
    inspectionDate: string,
    actionRequired: string,
    typedNotes: string,
    currentDate: string
  ) => void
  onInspectionmodalExit: () => void
  previousForms: string[]
  setPreviousForms: (e: string[]) => void
}

export function InspectionModal(props: inspectionModalProps) {
  const damageLevels: string[] = ['P1', 'P2', 'P3', 'P4']
  const [typedActionRequired, setTypedActionRequired] = useState<string>('')
  const [toggleDamageLevel, setToggleDamageLevel] = useState<string>('')
  const [selectedNextInspectionDate, setSelectedNextInspectionDate] = useState<string>('')
  const [typedNotes, setTypedNotes] = useState<string>('')

  const currentDate = new Date()

  useEffect(() => {
    setTypedActionRequired(props.actionRequired)
    setToggleDamageLevel(props.damageLevel)
    setSelectedNextInspectionDate(props.nextInspectionDate)
  }, [props.show, props.actionRequired, props.damageLevel, props.nextInspectionDate])

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.onInspectionmodalExit()}
      size='lg'>
      <Modal.Header closeButton className='text-center'>
        <Modal.Title className='w-100 TankInspector__modal-title'>
          {props.tankName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='TankInspector__modal-body'>
        <Form>
          <Row>
            <Col xs={6}>
              <h5 className='TankInspector__inspection-metadata-text'>
                <b>Date Inspected:</b>
              </h5>
              <br />
              <h5 className='TankInspector__inspection-metadata-text'>
                <b>Inspector:</b>
              </h5>
              <br />

              <h5 className='TankInspector__inspection-metadata-text'>
                <b>Inspection Types Completed:</b>
              </h5>
              <br />

              <h5 className='TankInspector__inspection-metadata-text'>
                <b>Entry Required For Inspection:</b>
              </h5>
            </Col>
            <Col xs={6}>
              <h5 className='TankInspector__inspection-metadata-text'>
                {props.dateInspected}
              </h5>
              <br />

              <h5 className='TankInspector__inspection-metadata-text'>
                {props.inspector}
              </h5>
              <br />

              <h5 className='TankInspector__inspection-metadata-text'>
                {props.inspectionTypes}
              </h5>
              <br />

              <h5 className='TankInspector__inspection-metadata-text'>
                {props.entryRequired}
              </h5>
            </Col>
          </Row>
          <br></br>
          <Form.Group>
            <Form.Label>
              <b>Action Required: </b>
            </Form.Label>
            <Form.Control
              type='text'
              onChange={(e) => {
                setTypedActionRequired(e.target.value)
              }}
              value={typedActionRequired}
              // placeholder=" *Required* Example: 2-66-2-V"
            />
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Label>
              <b>Damage Level: </b>
            </Form.Label>
            <Form.Select
              className='TankInspector__damage-select'
              aria-label='Floating label select example'
              onChange={(e) => {
                setToggleDamageLevel(e.target.value)
              }}>
              {damageLevels.map((e) => {
                return (
                  <option className='TankInspector__damage-option' value={e} key={e}>
                    {e}
                  </option>
                )
              })}
            </Form.Select>
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Label>
              <b>Next Inspection Date: </b>
            </Form.Label>
            <Form.Control
              type='date'
              onChange={(e) => {
                setSelectedNextInspectionDate(e.target.value)
              }}
              value={selectedNextInspectionDate}
              // placeholder=" *Required* Example: 2-66-2-V"
            />
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Label>
              <b>Notes: </b>
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              onChange={(e) => {
                setTypedNotes(e.target.value)
              }}
              value={typedNotes}
              // placeholder=" *Required* Example: 2-66-2-V"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          type='submit'
          onClick={() =>
            props.onInspectionModalHide(
              toggleDamageLevel,
              selectedNextInspectionDate,
              typedActionRequired,
              typedNotes,
              currentDate.toDateString()
            )
          }>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
