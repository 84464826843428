import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { Card } from 'react-bootstrap'
import { FloatingLabel } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { sampleAnnotations } from '../test_data/tanks/Aircraft_Carriers/sample_annotations'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Viewer from 'react-viewer'
import { MiniOracle } from '../components/Mini Oracle/miniOracle'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { InspectionModal } from '../components/Inspection Modal/inspectionModal'
import { AnnotationModal } from '../components/Annotation Modal/annotationModal'
import tank1 from '../test_data/tanks/Aircraft_Carriers/Midway/Midway/Tank-1/tank-1-info.json'
import tank2 from '../test_data/tanks/Aircraft_Carriers/Midway/Midway/Tank-2/tank-2-info.json'
import tank3 from '../test_data/tanks/Aircraft_Carriers/Midway/Midway/Tank-3/tank-3-info.json'
import tank4 from '../test_data/tanks/Aircraft_Carriers/Midway/Midway/Tank-4/tank-4-info.json'
import { Auth0Audience } from 'api/helpers'
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { getAscansUseState, searchAscans } from 'api/Images'
import { searchImages } from 'api/Images'
import { searchVideos } from 'api/Images'
import { getImagePush } from 'api/Images'
import { stackOffsetDiverging } from 'd3'
import { FormGenerator } from 'components/Form Generator/formGenerator'

/* eslint @typescript-eslint/no-var-requires: "off" */

interface radio {
  name: string
  value: string
}

type radios = Array<radio>

const TankInspector = (): JSX.Element => {
  const [tokenString, setToken] = useState<string>('')
  const { getAccessTokenSilently } = useAuth0()

  const radios: radios = [
    { name: 'Videos', value: '1' },
    { name: 'Photos', value: '2' },
  ]

  const data: any = require('../test_data/tanks/Aircraft_Carriers/Midway/Midway/Tank-1/tank1_3d_compressed_colored.csv')

  // const { state }: any = useLocation()
  const stateDict: any = {
    ascansBol: true,
    photosBol: true,
    shipClass: 'Midway',
    shipName: 'Midway',
    shipType: 'Aircraft_Carriers',
    tankName: 'Tank 1',
    unitId: 'uss-midway-tank-1',
    video_link:
      'https://www.youtube.com/watch?v=vkobWfP73yE&ab_channel=WB_Gecko_Robotics',
    videosBol: true,
  }

  const [state, setState] = useState<any>(stateDict)
  const navigate: NavigateFunction = useNavigate()
  const player: any = React.createRef()

  const [annotations, setAnnotations] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showInspectionModal, setShowInspectionModal] = useState<boolean>(false)
  const [currentTimestamp, setCurrentTimestamp] = useState<number>(0)
  const [radioValue, setRadioValue] = useState<string>('1')
  const [videoList, setVideoList] = useState<string[]>([])
  const [selectedVideo, setSelectedVideo] = useState<string>('')
  const [selectedPhoto, setSelectedPhoto] = useState<any>()

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(0)
  const [photosVisible, setPhotosVisible] = useState<boolean>(false)
  // const [mode, setMode] = useState("inline");
  // const [openCollapse, setOpenCollapse] = useState(false);
  const [actionRequired, setActionRequired] = useState<string>('')
  const [damageLevel, setDamageLevel] = useState<string>('')

  // eslint-disable-next-line
  const [nextInspectionDate, setNextInspectionDate] = useState<string>('')
  const [utBool, setUtBool] = useState<boolean>(false)
  const [inspectionDate, setInspectionDate] = useState<string>('')
  const [entryRequired, setEntryRequired] = useState<string>('')
  const [inspectionTypesRequired, setInspectionTypesRequired] = useState<string>('')
  const [inspector, setInspector] = useState<string>('')
  const [ascansInfo, setAscansInfo] = useState<any[]>([])
  const [videosInfo, setVideosInfo] = useState<any[]>([])
  const [photosInfo, setPhotosInfo] = useState<any[]>([])
  const [imageURLs, setImageURLs] = useState<(Blob | null)[]>([])
  const [viewerPhotosList, setViewerPhotosList] = useState<any[]>([])
  const [ascanCSV, setAscanCSV] = useState<any>('')
  const [previousForms, setPreviousForms] = useState<string[]>([])
  const [formTimestamps, setFormTimestamps] = useState<string[]>([])

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const options = { audience: Auth0Audience() }
    const token = await getAccessTokenSilently(options)
    setToken(token)
  }

  useEffect(() => {
    if (state == null) {
      navigate('/')
    } else {
      getToken(getAccessTokenSilently)
    }
  }, [])

  useEffect(() => {
    if (tokenString !== '') {
      if (state.ascansBol) {
        searchAscans(tokenString, 'unit', state.unitId, setAscansInfo)
      }
      if (state.photosBol) {
        searchImages(tokenString, 'unit', state.unitId, setPhotosInfo)
      }
      if (state.videosBol) {
        searchVideos(tokenString, 'unit', state.unitId, setVideosInfo)
      }
    }
  }, [tokenString])

  useEffect(() => {
    if (ascansInfo.length > 0) {
      getAscansUseState(ascansInfo[0].id, tokenString, setAscanCSV)
    }
  }, [ascansInfo])

  useEffect(() => {
    if (photosInfo.length > 0 && imageURLs.length < photosInfo.length) {
      photosInfo.map((photo: any) => {
        getImagePush(photo.id, tokenString, setImageURLs)
      })
    }
  }, [photosInfo])

  useEffect(() => {
    if (imageURLs.length === photosInfo.length) {
      let viewerList: any[] = []
      imageURLs.map((imageURL) => {
        viewerList.push({ src: imageURL })
      })
      setViewerPhotosList(viewerList)
      setSelectedPhoto(imageURLs[0])
    }
  }, [imageURLs])

  useEffect(() => {
    if (state.videosBol) {
      setVideoList((oldArray: any) => [...oldArray, state.video_link])
    }
    if (state.tankName.includes('1')) {
      // let utbool = tank1.ut.length === 0 ? 'false' : 'true'
      // setVideoList(tank1.videos)
      // setPhotoList(tank1.photos)
      // setSelectedPhoto(tank1.photos[0])
      // setSelectedVideo(tank1.videos[0])
      // setAnnotations(sampleAnnotations.tank1)

      setUtBool(state.ascansBol)
      setInspectionDate(tank1.dateInspected)
      setDamageLevel(tank1.damageLevel)
      setActionRequired(tank1.actionRequired)
      setEntryRequired(tank1.entryRequired)
      setInspectionTypesRequired(tank1.inspectionTypesRequired)
      setInspector(tank1.inspector)
      setNextInspectionDate(tank1.nextInspectionDate)
      setAnnotations(tank1.annotations)
    } else if (state.tankName.includes('2')) {
      // let utbool = tank2.ut.length === 0 ? 'false' : 'true'
      // setVideoList(tank2.videos)
      // setPhotoList(tank2.photos)
      // setSelectedPhoto(tank2.photos[0])
      // setSelectedVideo(tank2.videos[0])
      // setAnnotations(sampleAnnotations.tank2)
      // setUtBool(utbool)
      setUtBool(state.ascansBol)
      setInspectionDate(tank2.dateInspected)
      setDamageLevel(tank2.damageLevel)
      setActionRequired(tank2.actionRequired)
      setEntryRequired(tank2.entryRequired)
      setInspectionTypesRequired(tank2.inspectionTypesRequired)
      setInspector(tank2.inspector)
      setNextInspectionDate(tank2.nextInspectionDate)
      setAnnotations(tank2.annotations)
    } else if (state.tankName.includes('3')) {
      // let utbool = tank3.ut.length === 0 ? 'false' : 'true'
      // setVideoList(tank3.videos)
      // setPhotoList(tank3.photos)
      // setSelectedPhoto(tank3.photos[0])
      // setSelectedVideo(tank3.videos[0])
      // setAnnotations(sampleAnnotations.tank3)
      // setUtBool(utbool)
      setUtBool(state.ascansBol)
      setInspectionDate(tank3.dateInspected)
      setDamageLevel(tank3.damageLevel)
      setActionRequired(tank3.actionRequired)
      setEntryRequired(tank3.entryRequired)
      setInspectionTypesRequired(tank3.inspectionTypesRequired)
      setInspector(tank3.inspector)
      setNextInspectionDate(tank3.nextInspectionDate)
      setAnnotations(tank3.annotations)
    } else if (state.tankName.includes('4')) {
      // let utbool = tank4.ut.length === 0 ? 'false' : 'true'
      // setVideoList(tank4.videos)
      // setPhotoList(tank4.photos)
      // setSelectedPhoto(tank4.photos[0])
      // setSelectedVideo(tank4.videos[0])
      // setAnnotations(sampleAnnotations.tank4)
      // setUtBool(utbool)
      setUtBool(state.ascansBol)
      setInspectionDate(tank4.dateInspected)
      setDamageLevel(tank4.damageLevel)
      setActionRequired(tank4.actionRequired)
      setEntryRequired(tank4.entryRequired)
      setInspectionTypesRequired(tank4.inspectionTypesRequired)
      setInspector(tank4.inspector)
      setNextInspectionDate(tank4.nextInspectionDate)
      setAnnotations(tank4.annotations)
    }
  }, [state])

  useEffect(() => {
    if (videoList.length > 0) {
      setSelectedVideo(videoList[0])
    }
  }, [videoList])

  const previousPicture = () => {
    if (selectedPhotoIndex !== 0) {
      setSelectedPhotoIndex(selectedPhotoIndex - 1)
      setSelectedPhoto(imageURLs[selectedPhotoIndex - 1])
    }
  }

  const nextPicture = () => {
    const totalPhotoListSize = imageURLs.length
    if (selectedPhotoIndex !== totalPhotoListSize - 1) {
      setSelectedPhotoIndex(selectedPhotoIndex + 1)
      setSelectedPhoto(imageURLs[selectedPhotoIndex + 1])
    }
  }

  // const setCollapse = () => {
  //   if (openCollapse === true) {
  //     setOpenCollapse(false);
  //   } else {
  //     setOpenCollapse(true);
  //   }
  // };

  const setVisible = () => {
    if (photosVisible === true) {
      setPhotosVisible(false)
    } else {
      setPhotosVisible(true)
    }
  }

  const changeMedia = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVideo(e.target.value)
  }

  const playerSkip = (e: React.ChangeEvent<HTMLSelectElement>) => {
    player.current.seekTo(e.target.value)
  }
  const toggleShowModal = () => {
    const time = player.current.getCurrentTime().toFixed(2)
    setShowModal(true)
    setCurrentTimestamp(time)
  }
  const onModalHide = () => {
    setShowModal(false)
  }

  // const showInspectionModal = () => {
  //   setShowInspectionModal(true);
  // }

  const onInspectionModalHide = (
    damage: string,
    inspectionDate: string,
    actionRequired: string,
    typedNotes: string,
    currentDate: string
  ) => {
    setShowInspectionModal(false)
    setDamageLevel(damage)
    setActionRequired(actionRequired)
    setNextInspectionDate(inspectionDate)

    //create string for form submission
    const formString = `
    Tank Name: ${state.tankName} |
    Date Inspected: ${inspectionDate} |
    Inspector: ${inspector} |
    Inspection Types Completed: ${inspectionTypesRequired} |
    Entry Required: ${entryRequired} |
    Action Required: ${actionRequired} | 
    Damage Level: ${damage} | 
    Next Inspection Date: ${inspectionDate} | 
    Notes: ${typedNotes}`

    // add formString to previousForms array
    setPreviousForms((prevArray) => [...prevArray, formString])
    setFormTimestamps((prevArray) => [...prevArray, currentDate])
  }

  const onInspectionModalExit = () => {
    setShowInspectionModal(false)
  }

  const handleTankAdded = (modalAnnotation: string) => {
    setAnnotations((prevArray) => [
      ...prevArray,
      [
        modalAnnotation,
        currentTimestamp,
        4,
        annotations[annotations.length - 1][2] + 1,
        'video',
      ],
    ])

    setShowModal(false)
  }

  // const setRadioValue = (e) => {
  //   setRadioValue(e)
  // }

  const chooseVideoOrPhoto = () => {
    if (radioValue === '1') {
      return (
        <div>
          {/* <FloatingLabel
            className='TankInspector__media-select'
            controlId='floatingSelect'
            label='Select Media'>
            <Form.Select
              className='TankInspector__media-select-form'
              aria-label='Floating label select example'
              onChange={(e) => {
                changeMedia(e)
              }}>
              {videoList.map((e, index) => {
                // let videoFileName = e.split('/')[e.split('/').length - 1]
                return (
                  <option value={e} key={`${state.tankName} - ${index}`}>
                    {e}
                  </option>
                )
              })}
            </Form.Select>
          </FloatingLabel> */}
          <div className='TankInspector__player-wrapper'>
            <ReactPlayer
              className='TankInspector__react-player'
              playing={false}
              url={selectedVideo}
              // url={
              //   "https://www.youtube.com/watch?v=Wqy3PBEglXQ&ab_channel=Fireship"
              // }
              controls={true}
              ref={player}></ReactPlayer>
          </div>
          <FloatingLabel controlId='floatingSelect' label='Search Annotations'>
            <Form.Select
              aria-label='Floating label select example'
              onChange={(e) => {
                playerSkip(e)
              }}>
              {annotations.map((e) => {
                return (
                  <option value={e[1]} key={e[1]}>
                    {`${e[2]} - ${e[0]} - ${e[1]}s`}
                  </option>
                )
              })}
            </Form.Select>
          </FloatingLabel>
          <div className='TankInspector__button-div'>
            <Button variant='success' onClick={() => toggleShowModal()}>
              Add an Annotation at this Timestamp
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className='TankInspector__photo-viewer-div'>
            <img
              className='TankInspector__selected-image'
              src={selectedPhoto}
              alt='tank damage'></img>
          </div>
          <div className='TankInspector__photo-buttons-div'>
            {' '}
            <IconContext.Provider
              value={{
                style: {
                  fontSize: '75px',
                  color: '#db6955',
                  // horizontalAlign: "middle",
                  verticalAlign: 'top',
                },
              }}>
              <FaArrowAltCircleLeft
                onClick={() => previousPicture()}></FaArrowAltCircleLeft>
            </IconContext.Provider>{' '}
            <IconContext.Provider
              value={{
                style: {
                  fontSize: '75px',
                  color: '#db6955',
                  // horizontalAlign: "middle",
                  verticalAlign: 'top',
                },
              }}>
              <FaArrowAltCircleRight
                onClick={() => nextPicture()}></FaArrowAltCircleRight>
            </IconContext.Provider>
          </div>
          <div className='TankInspector__expand-images-div'>
            <Button variant='success' onClick={() => setVisible()}>
              Expand Images
            </Button>
          </div>

          <Viewer
            // container={document.getElementById("container")}
            visible={photosVisible}
            onClose={() => {
              setVisible()
            }}
            images={viewerPhotosList}
          />
        </div>
      )
    }
  }
  if (
    viewerPhotosList.length === imageURLs.length &&
    imageURLs.length > 0 &&
    selectedPhoto !== null
  ) {
    return (
      <div className='TankInspector__inspection-master-div'>
        <AnnotationModal
          show={showModal}
          currentTimestamp={currentTimestamp}
          onModalHide={onModalHide}
          handleTankAdded={handleTankAdded}></AnnotationModal>
        <InspectionModal
          show={showInspectionModal}
          tankName={state.tankName}
          dateInspected={inspectionDate}
          inspector={inspector}
          inspectionTypes={inspectionTypesRequired}
          entryRequired={entryRequired}
          damageLevel={damageLevel}
          actionRequired={actionRequired}
          nextInspectionDate={nextInspectionDate}
          onInspectionModalHide={onInspectionModalHide}
          onInspectionmodalExit={onInspectionModalExit}
          previousForms={previousForms}
          setPreviousForms={setPreviousForms}></InspectionModal>
        <div className='TankInspector__content-master-div'>
          <div className='TankInspector__collapse-button-div'>
            <Card className='TankInspector__damage-card'>
              <Card.Body>
                <b>Damage Level: </b>
                <b style={{ color: '#db6955' }}>{damageLevel}</b>
              </Card.Body>
            </Card>
            <Button
              className='TankInspector__inspection-details-button'
              style={{ border: '0px' }}
              onClick={() => {
                setShowInspectionModal(true)
              }}>
              View Inspection Details
            </Button>
            <Card className='TankInspector__action-card'>
              <Card.Body>
                <b>Action Required: </b>{' '}
                <b style={{ color: '#008677 ' }}>{actionRequired}</b>
              </Card.Body>
            </Card>
          </div>
          <div className='TankInspector__subwindows-parent-div'>
            <div className='TankInspector__subwindow-col'>
              <div className='TankInspector__spacer-div'></div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='TankInspector__white-text'>
                  <b>LVL 1 Inspection</b>
                </h5>
              </div>
              <div className='TankInspector__inspection-subwindow-div'>
                <Card className='TankInspector__lvl1-card'>
                  <Card.Body className='TankInspector__lvl1-card-body'>
                    <div className='TankInspector__toggle-video-div'>
                      <ButtonGroup className='TankInspector__toggle-button-group'>
                        {radios.map((radio, idx) => (
                          <ToggleButton
                            className='TankInspector__toggle-button'
                            key={idx}
                            id={`radio-${idx}`}
                            type='radio'
                            variant={'outline-light'}
                            name='radio'
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}>
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div>
                    {chooseVideoOrPhoto()}
                  </Card.Body>
                </Card>
              </div>
              <div className='TankInspector__spacer-div'></div>
            </div>
            {/* <Col xs={1}></Col> */}
            <div className='TankInspector__inspection-subwindow-col'>
              <div className='TankInspector__spacer-div'></div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='TankInspector__white-text'>
                  <b>LVL 2 Inspection</b>
                </h5>
              </div>
              <div className='TankInspector__inspection-subwindow-div'>
                {utBool === true && ascanCSV !== '' ? (
                  // <MiniOracle data={data}></MiniOracle>
                  <MiniOracle
                    data={ascanCSV}
                    setSelectedPhoto={setSelectedPhoto}
                    imageUrls={imageURLs}
                    radioValue={radioValue}
                    setRadioValue={setRadioValue}></MiniOracle>
                ) : (
                  <Card>
                    <Card.Body>
                      <h1>No LVL 2 inspection recorded.</h1>
                    </Card.Body>
                  </Card>
                )}
              </div>
              <div className='TankInspector__spacer-div'></div>
            </div>
          </div>
        </div>
        {previousForms.length > 0 ? (
          <FormGenerator
            previousForms={previousForms}
            setPreviousForms={setPreviousForms}
            formTimestamps={formTimestamps}
            setFormTimestamps={setFormTimestamps}
          />
        ) : null}
      </div>
    )
  } else {
    return <div>loading</div>
  }
}

export default TankInspector
