import { useState, useEffect } from 'react'
import './formGenerator.css'
import { FormatFormStrings } from 'components/Format Form Strings/formatFormStrings'
import { PDFDownloadLink, StyleSheet, Document, Page, Text } from '@react-pdf/renderer'

export interface FormGeneratorProps {
  previousForms: string[]
  setPreviousForms: (e: string[]) => void
  formTimestamps: string[]
  setFormTimestamps: (e: string[]) => void
}

export interface MyDocumentProps {
  text: string
  date: string
}

export function MyDocument(props: MyDocumentProps) {
  const [title, setTitle] = useState<string>('')
  const [dateInspected, setDateInspected] = useState<string>('')
  const [headers, setHeaders] = useState<string[]>([])
  const [values, setValues] = useState<string[]>([])

  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#ffffff',
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      textAlign: 'center',
      fontSize: '24pt',
      fontWeight: 'bold',
      marginBottom: '0.5cm',
    },
    keywords: {
      fontSize: '18pt',
      marginBottom: '0.5cm',
      underline: true,
    },
    subtitle: {
      textAlign: 'center',
      fontSize: '18pt',
      marginBottom: '0.5cm',
    },
    paragraph: {
      fontSize: '12pt',
      lineHeight: 1.5,
      marginBottom: '0.5cm',
      flex: 1,
      flexGrow: 1,
    },
  })

  useEffect(() => {
    const formStrings = props.text.split('|')
    const title = formStrings[0].split(':')[1]
    //loop through formStrings and split on ':'
    //add the first part of the split to headers
    //add the second part of the split to values
    const headers: string[] = []
    const values: string[] = []
    for (let i = 1; i < formStrings.length; i++) {
      const splitString = formStrings[i].split(':')
      headers.push(splitString[0])
      values.push(splitString[1])
    }
    setTitle(title)
    setDateInspected(props.date)
    setHeaders(headers)
    setValues(values)
  }, [])

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{dateInspected}</Text>
        {headers.map((header, index) => {
          return (
            <Text>
              <Text key={index} style={styles.keywords}>
                {header}:
              </Text>
              <Text style={styles.paragraph}>{values[index]}</Text>
            </Text>
          )
        })}
      </Page>
    </Document>
  )
}

export function FormGenerator(props: FormGeneratorProps) {
  return (
    <div className='TankInspector__FormGenerator-master-div'>
      <h1 className='TankInspector__FormGenerator-title-h1'>Previous Form Submissions</h1>
      {props.previousForms.map((formString, index) => {
        return (
          <div key={index} className='TankInspector__FormGenerator-form-div-card'>
            <div className='TankInspector__FormGenerator-form-text-div'>
              <h3 className='TankInspector__FormGenerator-form-card-header-h3'>
                {props.formTimestamps[index]}
              </h3>
              <FormatFormStrings formStrings={formString.split('|')} />
            </div>
            <div className='TankInspector__FormGenerator-form-button-div'>
              <button
                className='TankInspector__FormGenerator-form-button'
                onClick={() => {
                  const newPreviousForms = props.previousForms.filter(
                    (form, i) => i !== index
                  )
                  props.setPreviousForms(newPreviousForms)
                }}>
                Delete
              </button>
              <div className='TankInspector__FormGenerator-form-button-download'>
                <PDFDownloadLink
                  document={
                    <MyDocument text={formString} date={props.formTimestamps[index]} />
                  }
                  fileName='Tank_Report.pdf'>
                  Download PDF
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
