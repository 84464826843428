import Papa from 'papaparse'

export function getImageUseState(fileName: string, token: string, imageSetter: any): any {
  fetch(
    `https://object-service.dev.cloud.geckorobotics.com/api/v1/files/tank_inspector_photos/${fileName}/download`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.blob())
    .then((blob) => {
      imageSetter(blob)
    })
}

export function getImagePush(fileName: string, token: string, imageSetter: any): any {
  fetch(
    `https://object-service.dev.cloud.geckorobotics.com/api/v1/files/tank_inspector_photos/${fileName}/download`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.blob())
    .then((blob) => {
      imageSetter((oldArray: any) => [...oldArray, URL.createObjectURL(blob)])
    })
}

export function getVideoUseState(fileName: string, token: string, videoSetter: any): any {
  fetch(
    `https://object-service.dev.cloud.geckorobotics.com/api/v1/files/tank_inspector_photos/${fileName}/download`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.blob())
    .then((blob) => {
      videoSetter(blob)
    })
}

export function getAscansUseState(
  fileName: string,
  token: string,
  ascanSetter: any
): any {
  fetch(
    `https://object-service.dev.cloud.geckorobotics.com/api/v1/files/tank_inspector_3d_ascans/${fileName}/download`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.text())
    .then((csv: any) => {
      let parsedCSV: any = Papa.parse(csv, { header: true })
      ascanSetter(parsedCSV)
    })
}

export function searchImages(
  token: string,
  tagKey: string,
  tagValue: string,
  imageInfoSetter: any
): any {
  let data
  if (tagKey === 'site') {
    data = { tags: { site: tagValue } }
  } else {
    data = { tags: { unit: tagValue } }
  }

  fetch(
    'https://object-service.dev.cloud.geckorobotics.com/api/v1/files/search/tank_inspector_photos',
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(data),
    }
  )
    .then((data) => data.json())
    .then((data) => {
      imageInfoSetter(data)
    })
}

export function searchVideos(
  token: string,
  tagKey: string,
  tagValue: string,
  videoInfoSetter: any
): any {
  let data
  if (tagKey === 'site') {
    data = { tags: { site: tagValue } }
  } else {
    data = { tags: { unit: tagValue } }
  }

  fetch(
    'https://object-service.dev.cloud.geckorobotics.com/api/v1/files/search/tank_inspector_videos',
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(data),
    }
  )
    .then((data) => data.json())
    .then((data) => {
      videoInfoSetter(data)
    })
}

export function searchAscans(
  token: string,
  tagKey: string,
  tagValue: string,
  ascansInfoSetter: any
): any {
  let data
  if (tagKey === 'site') {
    data = { tags: { site: tagValue } }
  } else {
    data = { tags: { unit: tagValue } }
  }

  fetch(
    'https://object-service.dev.cloud.geckorobotics.com/api/v1/files/search/tank_inspector_3d_ascans',
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(data),
    }
  )
    .then((data) => data.json())
    .then((data) => {
      ascansInfoSetter(data)
    })
}

export default getImageUseState
