import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoggedInContent from './LoggedInContent'
import LoadingContent from './LoadingContent'
import { LoginPage } from 'gecko-ui'

function Content() {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <LoadingContent />
  }
  if (isAuthenticated) {
    return <LoggedInContent />
  }
  return <LoginPage />
}

export default Content
